import React from "react";

import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/reiseapotheke"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div>
                          <div id="search_form">
                            <input
                              type="text"
                              name="search_block_form"
                              id="searchword"
                              maxLength="128"
                            />
                            <input
                              type="submit"
                              name="suchen"
                              id="searchsubmit"
                              defaultValue
                            />
                            <input
                              type="hidden"
                              name="form_token"
                              defaultValue="fpRQ2dP7NgBmLR_3_abvt9HZCsr1ssctdnKOS8-vJyo"
                            />
                            <input
                              type="hidden"
                              name="form_id"
                              defaultValue="search_block_form"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="meta-text">
                IMODIUM®.&nbsp;Wirkstoff: Loperamidhydrochlorid. Über Wirkung und mögliche unerwünschte Wirkungen informieren Gebrauchsinformation, Arzt oder Apotheker.
                </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/expertenrat/">Expertenrat</a>
                  <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p9">
                    Kontakt
                  </a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/wie-wirkt-imodium-akut/">Wie wirkt IMODIUM®?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727">
                      <a href="/fakten-uber-durchfall/">
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Diagnose Reizdarm</a>
                    </li>
                    <li className="menu-627 active-trail">
                      <a
                        href="/reisedurchfall-vorbeugen/"
                        className="active-trail"
                      >
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-subnavi">
                <div
                  id="block-menu-menu-durchfall-auf-reisen"
                  className="block block-menu"
                >
                  <h2>Durchfall auf Reisen</h2>
                  <div className="content">
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/reisedurchfall-vorbeugen/" title>
                          Vorbeugung von Reisedurchfall
                        </a>
                      </li>
                      <li className="leaf active-trail">
                        <a
                          href="/reiseapotheke/"
                          title
                          className="active-trail active"
                        >
                          Reiseapotheke
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/reisedurchfall-risikofaktoren/" title>
                          Reisedurchfall Risikofaktoren
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/reisedurchfall-infos-und-fakten/" title>
                          Infos &amp; Fakten zu Reisedurchfall
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              &nbsp;
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <div
                      about="/reiseapotheke"
                      typeof="sioc:Item foaf:Document"
                      className="ds-1col node node-simple-page view-mode-1_col clearfix"
                    >
                      <div>
                        <h1>Reiseapotheke</h1>
                        <div className="headimg border">
                          <img
                            typeof="foaf:Image"
                            src="/sites/default/files/reisen.jpg"
                            width="695"
                            height="286"
                          />{" "}
                        </div>
                        <div className="firstrow">
                          <p className="subline">
                            Insbesondere in Entwicklungsländern besteht häufig
                            nicht nur eine Sprachbarriere, sondern auch die
                            Medikamente vor Ort entsprechen nicht immer unseren
                            deutschen Anforderungen an Qualität, Wirksamkeit und
                            Sicherheit.{" "}
                          </p>
                        </div>
                        <div className="field field-name-field-body-left field-type-text-with-summary field-label-hidden">
                          <div className="field-items">
                            <div className="field-item even">
                              <div>
                                <h2>Reiseapotheke bei akutem Durchfall</h2>
                                <p>
                                  Akuter Durchfall ist eine häufig auftretende
                                  Reisekrankheit. Zu den Auslösern gehören
                                  mangelhafte Hygiene, unreines Trinkwasser,
                                  ungewohnte Speisen, aber auch Stress, der
                                  durch die Reise verursacht werden kann.
                                </p>
                                <p>
                                  Während Durchfall für die meisten Erwachsenen
                                  in erster Linie unangenehm und lästig ist,
                                  kann es gerade für Kinder und Senioren
                                  aufgrund des hohen Flüssigkeits- und
                                  Mineralienverlusts und der damit
                                  einhergehenden Dehydrierung schnell gefährlich
                                  werden. Deshalb sollte während einer
                                  Durchfallepisode die Zufuhr von Flüssigkeit
                                  und Mineralien höchste Priorität haben. Um den
                                  starken Flüssigkeits- und Mineralienverlust
                                  auszugleichen und den Durchfall zu stoppen,
                                  eignen sich Elektrolyte-Präparate und{" "}
                                  <a href="/imodium-akut-schmelztabletten/">
                                    Imodium® akut Schmelztabletten
                                  </a>
                                  &nbsp;und sollten in Ihrer Reiseapotheke auf
                                  keinen Fall fehlen. Informieren Sie sich unter
                                  Reisedurchfall Risikofaktoren über Länder mit
                                  erhöhtem Reisedurchfallrisiko.
                                </p>
                                <h2>&nbsp;</h2>
                                <h2>Checkliste für Ihre Reiseapotheke</h2>
                                <ul>
                                  <li>
                                    Mittel gegen akuten Reisedurchfall, z. B.{" "}
                                    <a href="/imodium-akut-schmelztabletten/">
                                      Imodium® akut Schmelztabletten
                                    </a>
                                  </li>
                                  <li>Elektrolytpräparat zur Rehydrierung</li>
                                  <li>Mittel gegen Verstopfung</li>
                                  <li>
                                    Präparat gegen Übelkeit / Reiseübelkeit und
                                    Erbrechen
                                  </li>
                                  <li>Desinfektionsmittel</li>
                                  <li>Wund- und Heilsalbe</li>
                                  <li>Pflaster und Verbandsmaterial</li>
                                  <li>Schmerzmittel</li>
                                  <li>Halsschmerztabletten</li>
                                  <li>Nasenspray</li>
                                  <li>Insektenschutzmittel</li>
                                  <li>
                                    juckreizstillendes Gel gegen Insektenstiche
                                  </li>
                                  <li>Sonnenschutz und Aftersun-Produkt</li>
                                  <li>kühlende Salbe gegen Sonnenbrand</li>
                                  <li>
                                    Mittel gegen Allergien (Antihistaminika)
                                  </li>
                                  <li>Creme gegen Hautpilze</li>
                                  <li>Verhütungsmittel</li>
                                  <li>Frauen-Hygieneartikel</li>
                                  <li>
                                    Persönliche Medikamente
                                    (verschreibungspflichtig)
                                  </li>
                                  <li>
                                    Zubehör: Schere, Pinzette, Fieberthermometer
                                  </li>
                                  <li>
                                    in einigen Ländern Malariamittel und
                                    Moskitonetz
                                  </li>
                                </ul>
                                <p>&nbsp;</p>
                                <div className="std-site">
                                  <div className="firstrow">
                                    <div className="left">
                                      <div className="quote">
                                        <div
                                          style={{
                                            top: "10px"
                                          }}
                                        >
                                          <div className="field field-name-field-center-title field-type-text-long field-label-hidden">
                                            <div className="field-items">
                                              <div className="field-item even">
                                                <h2>
                                                  Was gehört in eine Reise-
                                                  apotheke
                                                </h2>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <p>
                                  <strong>
                                    Laden Sie sich hier die Reisecheckliste
                                    herunter:
                                    <a
                                      className="btn"
                                      href="/sites/default/files/Imodium_AT--Reisecheckliste_AT-IM-2000025.pdf"
                                      target="_blank"
                                    >
                                      {" "}
                                    </a>
                                  </strong>
                                </p>
                                <div className="dl_btn">
                                  <a
                                    className="btn"
                                    href="/sites/default/files/Imodium_AT--Reisecheckliste_AT-IM-2000025.pdf"
                                    target="_blank"
                                  >
                                    <span className="start" style={{
                                      padding: "0 10px 0 12px"
                                                  }}>
                                      Jetzt Downloaden
                                    </span>
                                  </a>
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  &nbsp;
                                </div>
                                <p>&nbsp;</p>
                                <h3>Tipp:</h3>
                                <p>
                                  Überprüfen Sie vor jedem Urlaub den Inhalt
                                  Ihrer Reiseapotheke, damit Sie stets auf der
                                  sicheren Seite sind. Kontrollieren Sie dabei
                                  auch die Haltbarkeit der Medikamente.
                                </p>
                                <p>&nbsp;</p>
                                <h2>
                                  Was kann mit in Ihr Handgepäck und was sollte
                                  in den Koffer?
                                </h2>
                                <p>
                                  Eine Reise startet, sobald Sie Ihr Haus
                                  verlassen. Damit sich Ihre Vorbereitungen auch
                                  bezahlt machen, sollten Sie die wichtigsten
                                  Medikamente in Ihr Handgepäck packen. Aber was
                                  darf eigentlich mit in das Handgepäck? Wenn
                                  Sie mit dem Auto oder dem Zug verreisen,
                                  können Sie ohne Restriktionen die wichtigsten
                                  Medikamente aus Ihrer Reisapotheke mit ins
                                  Handgepäck nehmen.
                                </p>
                                <p>
                                  Sollten Sie aber mit dem Flugzeug verreisen,
                                  ist es wichtig, dass Sie sich vor der Reise
                                  mit den Restriktionen der einzelnen Flughäfen
                                  oder Airlines vertraut machen. In den meisten
                                  Fällen gilt: Flüssigkeiten bis 100 ml sind für
                                  das Handgepäck zulässig, sofern sie in einem
                                  transparenten verschließbaren Beutel, der ein
                                  Fassungsvermögen von einem Liter nicht
                                  überschreitet, verpackt sind. Nehmen Sie die
                                  Medikamente mit, die für Sie auf einem Flug am
                                  wichtigsten sind.
                                </p>
                                <p>
                                  Wichtig: Spitze Gegenstände, wie Scheren und
                                  Pinzetten verstauen Sie jedoch im Koffer,
                                  sonst werden Ihnen diese spätestens an der
                                  Sicherheitskontrolle abgenommen.
                                </p>
                              </div>
                              <div>&nbsp;</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="region region-special-block">
                <div id="block-block-8" className="block block-block">
                  <div className="content">
                    <h3>Welches IMODIUM® ist das Richtige für mich?</h3>
                    <p>
                      <img
                        alt
                        src="/sites/default/files/Imodium_Range_2_Produkte_699x275.jpg"
                        style={{
                          width: "699px",
                          height: "274px"
                        }}
                      />
                    </p>
                    <div className="nextbtn">
                      <a className="btn" href="/imodium-produkte/">
                        <span className="start">Produktübersicht</span>
                        <span className="end">&nbsp;</span>
                      </a>
                    </div>
                    <div className="clear">&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <div id="footer">
          <div className="wrap">
            <div className="sitemap">
              <div className="col1">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/wie-wirkt-imodium-akut/" title>
                      Wie wirkt IMODIUM®?
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/loperamid/" title>
                      Der Wirkstoff: Loperamid
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last expanded">
                    <a href="/imodium-produkte/" title>
                      Produktübersicht
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/imodium-akut-schmelztabletten/" title>
                          IMODIUM® akut Schmelztabletten
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/imodium-kapseln/" title>
                          IMODIUM® Kapseln
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="col2">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/fakten-uber-durchfall/" title>
                      Fakten über Durchfall
                    </a>
                  </li>
                  <li className="expanded">
                    <a href="/tipps-bei-durchfall/" title>
                      Tipps bei Durchfall
                    </a>
                    <ul className="menu">
                      <li className="first last leaf">
                        <a href="/tipps-fuer-den-winter/" title>
                          Tipps für den Winter
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="leaf">
                    <a href="/ursachen-von-durchfall/" title>
                      Ursachen von Durchfall
                    </a>
                  </li>
                  <li className="expanded">
                    <a href="/richtig-essen/" title>
                      Gesunde Essgewohnheiten
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/nahrungsmittelallergie/" title>
                          Nahrungsmittelallergien und -unverträglichkeiten
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/lebensmittelvergiftung/" title>
                          Lebensmittelvergiftung
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/rezepte/" title>
                          Gesunde Rezepte
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="expanded">
                    <a href="/stress-durchfall/" title>
                      Stress und Durchfall
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/hohe-arbeitsbelastung/" title>
                          Hohe Arbeitsbelastung
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/prufungssituationen/" title>
                          Prüfungssituationen
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="leaf">
                    <a href="/chronischer-durchfall/" title>
                      Chronischer Durchfall
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/gastroenteritis/" title>
                      Magen-Darm-Grippe
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/norovirus/" title>
                      Norovirus
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/durchfall-wahrend-ihrer-periode/" title>
                      Durchfall während Ihrer Periode
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="col3">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/diagnose-reizdarm/" title>
                      Diagnose Reizdarm (IBS)
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/behandlung-eines-reizdarms/" title>
                      Behandlung eines Reizdarms
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/reizdarm-ernahrung/" title>
                      Ernährung bei Reizdarm
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/reisedurchfall-vorbeugen/" title>
                      Vorbeugung von Reisedurchfall
                    </a>
                  </li>
                  <li className="leaf active-trail">
                    <a
                      href="/reiseapotheke/"
                      title
                      className="active-trail active"
                    >
                      Reiseapotheke
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/reisedurchfall-risikofaktoren/" title>
                      Reisedurchfall Risikofaktoren
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/reisedurchfall-infos-und-fakten/" title>
                      Infos &amp; Fakten zu Reisedurchfall
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="col4">
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/durchfall-bei-kindern/" title>
                      Durchfall bei Kindern
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/infomaterial/" title>
                      Infomaterial
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/faq-page/" title>
                      FAQ
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/glossar/" title>
                      Glossar
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="clear" />
            </div>
            <div className="subnavi">
              <div className="region region-metanavi">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/impressum/" title>
                      Impressum
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/kontakt/" title>
                      Kontakt
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/rechtliche-hinweise/" title>
                      Nutzungsbedingungen
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/datenschutz/" title>
                      Datenschutz
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/cookie-richtlinien/" title>
                      Cookie-Richtlinien
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                 <p>
                     IMODIUM®. Wirkstoff: Loperamidhydrochlorid. Über Wirkung und mögliche unerwünschte Wirkungen informieren Gebrauchsinformation, Arzt oder Apotheker.
                  </p>
                  <p>
                    <br />© Johnson &amp; Johnson GmbH 2011-2019. Diese Website
                    wird von der Johnson &amp; Johnson GmbH veröffentlicht, die
                    allein für deren Inhalte verantwortlich ist. Sie ist für
                    Besucher aus Österreich bestimmt.
                    <br />
                    Letzte Aktualisierung am: 23. Dezember 2021, AT-IM-2100045
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n<!--//--><!--[CDATA[//--><!--\nfunction euCookieComplianceLoadScripts() {}\n//--><!--]]-->\n"
          }}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--//--><!--[CDATA[//--><!--\nvar eu_cookie_compliance_cookie_name = "";\n//--><!--]]-->\n'
          }}
        />
        <script
          type="text/javascript"
          src="/sites/all/modules/contrib/eu_cookie_compliance/js/eu_cookie_compliance.js?qbpk52"
        />
      </div>
    );
  }
}

export default Page;
